import { DataTableColumnTypes } from "./enums";

export const answerTypeColumns = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Answer Category", type: DataTableColumnTypes.Text },
    { value: "Questions", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const predefinedAnswerColumns = [
    { value: "Answer Flag Mode", type: DataTableColumnTypes.Text },
    { value: "Default Predefined Answer Text Value", type: DataTableColumnTypes.Text },
];

export const predefinedAnswerTextColumns = [
    { value: "Language", type: DataTableColumnTypes.Text },
    { value: "Text Value", type: DataTableColumnTypes.Text },
];

export const auditColumnNames = [
    { value: "Event Date", type: DataTableColumnTypes.Sort },
    { value: "Event Time", type: DataTableColumnTypes.Sort },
    { value: "Event Type", type: DataTableColumnTypes.Sort },
    { value: "Table Name", type: DataTableColumnTypes.Sort },
    { value: "Primary Key", type: DataTableColumnTypes.Sort },
    { value: "User Name", type: DataTableColumnTypes.Sort },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const securityAuditColumnNames = [
    { value: "Event Date", type: DataTableColumnTypes.Sort },
    { value: "Event Time", type: DataTableColumnTypes.Sort },
    { value: "Event Type", type: DataTableColumnTypes.Sort },
    { value: "Status", type: DataTableColumnTypes.Text },
    { value: "User Name", type: DataTableColumnTypes.Sort },
    { value: "IP Address", type: DataTableColumnTypes.Sort },
];

export const checklistsColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Last Modified By", type: DataTableColumnTypes.Sort },
    { value: "Date Last Modified", type: DataTableColumnTypes.Sort },
    { value: "Question Sets", type: DataTableColumnTypes.Text },
    { value: "Checklist Assignments", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetQuestionsColumnNames = [
    { value: "Order Index", type: DataTableColumnTypes.Sort },
    { value: "ID", type: DataTableColumnTypes.Text },
    { value: "Type", type: DataTableColumnTypes.Sort },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Open Before Due", type: DataTableColumnTypes.PeriodInput },
    { value: "Due by Time", type: DataTableColumnTypes.TimeInput },
    { value: "Escalation L1 Time", type: DataTableColumnTypes.PeriodInput },
    { value: "Escalation L2 Time", type: DataTableColumnTypes.PeriodInput },
    { value: "Escalation L3 Time", type: DataTableColumnTypes.PeriodInput },
    { value: "Nested Questions", type: DataTableColumnTypes.DisplayNestedTableButton },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const existingQuestionsColumnNames = [
    { value: "ID", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Type", type: DataTableColumnTypes.Text },
    { value: "Category", type: DataTableColumnTypes.Text },
    { value: "AnswerType", type: DataTableColumnTypes.Text },
    { value: "Open Before Due", type: DataTableColumnTypes.PeriodInput },
    { value: "Due by Time", type: DataTableColumnTypes.TimeInput },
    { value: "Escalation L1 Time", type: DataTableColumnTypes.PeriodInput },
    { value: "Nested Questions", type: DataTableColumnTypes.DisplayNestedTableButton },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const nestedQuestionColumnNames = [
    { value: "Order Index", type: DataTableColumnTypes.Text },
    { value: "ID", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Category", type: DataTableColumnTypes.Text },
    { value: "Type", type: DataTableColumnTypes.Text },
    { value: "Answer Type", type: DataTableColumnTypes.Text },
    { value: "Render Mode", type: DataTableColumnTypes.Text },
    { value: "Parent Question Answer Trigger", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const orderQuestionsColumnNames = [
    { value: "Order Index", type: DataTableColumnTypes.Text },
    { value: "Question Id", type: DataTableColumnTypes.Text },
    { value: "Question Type", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
];

export const questionSetColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Process", type: DataTableColumnTypes.Sort },
    { value: "Schedule Description", type: DataTableColumnTypes.Sort },
    { value: "Questions", type: DataTableColumnTypes.Text },
    { value: "Last Modified By", type: DataTableColumnTypes.Sort },
    { value: "Date Last Modified", type: DataTableColumnTypes.Sort },
    { value: "Checklists", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedQuestionSetsToQuestionColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Description", type: DataTableColumnTypes.Text },
    { value: "Process", type: DataTableColumnTypes.Sort },
    { value: "Schedule Description", type: DataTableColumnTypes.Text },
    { value: "Questions", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associateQuestionSetColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Process", type: DataTableColumnTypes.Text },
    { value: "Schedule Description", type: DataTableColumnTypes.Text },
    { value: "Questions", type: DataTableColumnTypes.Text },
    { value: "Last Modified By", type: DataTableColumnTypes.Text },
    { value: "Date Last Modified", type: DataTableColumnTypes.Text },
    { value: "Checklists", type: DataTableColumnTypes.Text },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const questionCategoryColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Questions", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const tenantManagementColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Base URL", type: DataTableColumnTypes.Text },
    { value: "CSS Theme", type: DataTableColumnTypes.Text },
    { value: "Number of Users", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const roleColumnNames = [
    { value: "Role Name", type: DataTableColumnTypes.Text },
    { value: "User Count", type: DataTableColumnTypes.Text },
];

export const roleUserColumnNames = [
    { value: "Email", type: DataTableColumnTypes.Text },
    { value: "First Name", type: DataTableColumnTypes.Text },
    { value: "Last Name", type: DataTableColumnTypes.Text },
];

export const UsersInRoleColumnNames = [
    { value: "User ID", type: DataTableColumnTypes.Text },
    { value: "Email", type: DataTableColumnTypes.Text },
    { value: "Role", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const userManagementColumnNames = [
    { value: "Last Name", type: DataTableColumnTypes.Sort },
    { value: "First Name", type: DataTableColumnTypes.Sort },
    { value: "Email Address", type: DataTableColumnTypes.Sort },
    { value: "Modified By Username", type: DataTableColumnTypes.Sort },
    { value: "Date Modified", type: DataTableColumnTypes.Sort },
    { value: "Is Locked Out", type: DataTableColumnTypes.Text },
    { value: "Is Deleted", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const userGroupsColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Email", type: DataTableColumnTypes.Sort },
    { value: "Number of Users", type: DataTableColumnTypes.Text },
    { value: "Number of Nodes", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const nonWorkingDaySetColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Number of Non Working Days", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionsColumnNames = [
    { value: "ID", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Type", type: DataTableColumnTypes.Text },
    { value: "Category", type: DataTableColumnTypes.Text },
    { value: "Answer Type", type: DataTableColumnTypes.Text },
    { value: "Nested Questions", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associateChildQuestionsColumnNames = [
    { value: "ID", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Type", type: DataTableColumnTypes.Text },
    { value: "Category", type: DataTableColumnTypes.Text },
    { value: "Answer Type", type: DataTableColumnTypes.Text },
    { value: "Nested Questions", type: DataTableColumnTypes.Text },
    { value: "Trigger When Parent Desirable", type: DataTableColumnTypes.Check },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const referentialLinkTypeColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Sort },
    { value: "Referential Links", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const referentialLinkColumnNames = [
    { value: "Value", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedReferentialLinkColumnNames = [
    { value: "Referential Link Type", type: DataTableColumnTypes.Text },
    { value: "Value", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedReferentialLinkTypeColumnNames = [
    { value: "Referential Link Type", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associateReferentialLinkColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Value", type: DataTableColumnTypes.Text },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const wizardsColumnNames = [
    { value: "Wizard Name", type: DataTableColumnTypes.Text },
    { value: "Date Created", type: DataTableColumnTypes.Text },
    { value: "Created By", type: DataTableColumnTypes.Text },
    { value: "Wizard Released", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedNodesColumnNames = [
    { value: "Hierarchy Level", type: DataTableColumnTypes.Text },
    { value: "Hierarchy Level Value", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const assignNodeValuesColumnNames = [
    { value: "Hierarchy Level", type: DataTableColumnTypes.Text },
    { value: "Hierarchy Level Value", type: DataTableColumnTypes.Text },
    { value: "Select", type: DataTableColumnTypes.Text },
];

export const nameColumnName = [{ value: "Name", type: DataTableColumnTypes.Text }];

export const nameColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const nodesColumnNames = [
    { value: "Node", type: DataTableColumnTypes.Link },
    { value: "Path", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const linkedNodeTypeColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Required", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedNodeColumnNames = [
    { value: "Checklist Assignment Id", type: DataTableColumnTypes.Text },
    { value: "Node", type: DataTableColumnTypes.Text },
    { value: "Path", type: DataTableColumnTypes.Text },
    { value: "Is Enabled", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const actionItemDynamicFieldColumns = [
    { value: "Order Index", type: DataTableColumnTypes.Text },
    { value: "Field Name", type: DataTableColumnTypes.Link },
    { value: "Action Item Type", type: DataTableColumnTypes.Text },
    { value: "Value Type", type: DataTableColumnTypes.Text },
    { value: "Is Required", type: DataTableColumnTypes.Check },
];

export const actionItemDynamicFieldColumnsWithActions = [
    ...actionItemDynamicFieldColumns,
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const alternativeActionItemDynamicFieldTextsColumns = [
    { value: "Language", type: DataTableColumnTypes.Text },
    { value: "Dynamic Field Text", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const actionItemDynamicFieldTextsColumns = [
    { value: "Language", type: DataTableColumnTypes.Text },
    { value: "Dynamic Field Text", type: DataTableColumnTypes.Text },
    { value: "Default", type: DataTableColumnTypes.Check },
];

export const actionPlanColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Action Items", type: DataTableColumnTypes.Text },
    { value: "Action Plan Status", type: DataTableColumnTypes.Text },
];

export const associatedActionItemsColumnNames = [
    { value: "Action Item Id", type: DataTableColumnTypes.Text },
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Action Item Urgency", type: DataTableColumnTypes.Text },
    { value: "Accountable", type: DataTableColumnTypes.Text },
    { value: "Responsible", type: DataTableColumnTypes.Text },
    { value: "Action Instance Status", type: DataTableColumnTypes.Text },
    { value: "Start Date", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const filterActionItemsColumnNames = [
    { value: "Action Item Id", type: DataTableColumnTypes.Text },
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Action Item Type", type: DataTableColumnTypes.Text },
    { value: "Action Item Sub-Type", type: DataTableColumnTypes.Text },
    { value: "Action Instance Status", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const filterActionItemsAssignedToNodeColumnNames = [
    { value: "Action Item Id", type: DataTableColumnTypes.Text },
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Action Instance Status", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const filterUserAssignedActionItemsColumnNames = [
    { value: "Action Item Id", type: DataTableColumnTypes.Text },
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Action Instance Status", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const notificationTriggerColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Link },
    { value: "Should Trigger Before", type: DataTableColumnTypes.Check },
    { value: "Trigger Date", type: DataTableColumnTypes.Text },
    { value: "Trigger Time Interval", type: DataTableColumnTypes.Text },
    { value: "Send Window Interval", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const alternativeQuestionsTextsColumnNames = [
    { value: "Language", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const alternativeQuestionsTextsColumnNamesAlt = [
    { value: "Language", type: DataTableColumnTypes.Text },
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Default", type: DataTableColumnTypes.Check },
];

export const associatedUserNodeColumnNames = [
    { value: "Node", type: DataTableColumnTypes.Link },
    { value: "Path", type: DataTableColumnTypes.Text },
    { value: "User Group", type: DataTableColumnTypes.Text },
    { value: "Checklist Assignments", type: DataTableColumnTypes.IconLink },
    { value: "Applicable Checklist Assignments", type: DataTableColumnTypes.IconLink },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedUserGroupNodeColumnNames = [
    { value: "Node", type: DataTableColumnTypes.Link },
    { value: "Path", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const associatedUserGroupUserColumnNames = [
    { value: "User Name", type: DataTableColumnTypes.Link },
    { value: "User Email Address", type: DataTableColumnTypes.Text },
];

export const indicatorThresholdsColumnNames = [
    { value: "Threshold Indicator", type: DataTableColumnTypes.Text },
    { value: "Threshold Operator", type: DataTableColumnTypes.Text },
    { value: "Threshold Value", type: DataTableColumnTypes.Check },
];

export const reviewReportSetColumnNames = [
    { value: "Report Name", type: DataTableColumnTypes.Text },
    { value: "Rating", type: DataTableColumnTypes.Text },
    { value: "Status", type: DataTableColumnTypes.Check },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetInstanceReviewsColumnNames = [
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Question Set", type: DataTableColumnTypes.Text },
    { value: "Path", type: DataTableColumnTypes.Check },
    { value: "Date Created", type: DataTableColumnTypes.Text },
    { value: "Created By", type: DataTableColumnTypes.Text },
    { value: "Rating", type: DataTableColumnTypes.Text },
    { value: "Status", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetInstanceAnsweDetailsColumnNames = [
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Reviewer", type: DataTableColumnTypes.Text },
    { value: "Test Objective", type: DataTableColumnTypes.Check },
    { value: "Answer", type: DataTableColumnTypes.Text },
    { value: "Nested Answers", type: DataTableColumnTypes.DisplayNestedTableButton },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetInstanceAnswerFileColumnNames = [
    { value: "File Name", type: DataTableColumnTypes.Text },
    { value: "Date Uploaded", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetInstanceChildAnsweDetailsColumnNames = [
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Reviewer", type: DataTableColumnTypes.Text },
    { value: "Test Objective", type: DataTableColumnTypes.Check },
    { value: "Answer", type: DataTableColumnTypes.Text },
    { value: "Finding", type: DataTableColumnTypes.Text },
    { value: "Nested Answers", type: DataTableColumnTypes.DisplayNestedTableButton },
    { value: "Attached Evidence", type: DataTableColumnTypes.Text },
    { value: "Open Date Time", type: DataTableColumnTypes.Text },
    { value: "Action Item Due Date", type: DataTableColumnTypes.Text },
    { value: "Answer Status", type: DataTableColumnTypes.Text },
];

export const functionNodesColumns = [{ value: "Path", type: DataTableColumnTypes.Text }];

export const answerSummaryColumnNames = [
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Question", type: DataTableColumnTypes.Text },
    { value: "User", type: DataTableColumnTypes.Text },
    { value: "Answer", type: DataTableColumnTypes.Text },
    { value: "Comment", type: DataTableColumnTypes.Text },
    { value: "Open Date Time", type: DataTableColumnTypes.Text },
    { value: "Action Item Due Date", type: DataTableColumnTypes.Text },
    { value: "Nested Questions", type: DataTableColumnTypes.DisplayNestedTableButton },
    { value: "Answer Status", type: DataTableColumnTypes.Text },
    { value: "Status Date", type: DataTableColumnTypes.Text },
    { value: "Attached Evidence", type: DataTableColumnTypes.Text },
];

export const answerSummaryNestedAnswersColumnNames = [
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Question", type: DataTableColumnTypes.Text },
    { value: "User", type: DataTableColumnTypes.Text },
    { value: "Answer", type: DataTableColumnTypes.Text },
    { value: "Comment", type: DataTableColumnTypes.Text },
    { value: "Open Date Time", type: DataTableColumnTypes.Text },
    { value: "Action Item Due Date", type: DataTableColumnTypes.Text },
    { value: "Answer Status", type: DataTableColumnTypes.Text },
    { value: "Attached Evidence", type: DataTableColumnTypes.Text },
];

export const emailQueueColumnNames = [
    { value: "Date Sent", type: DataTableColumnTypes.Sort },
    { value: "Time Sent", type: DataTableColumnTypes.Text },
    { value: "Sent", type: DataTableColumnTypes.Check },
    { value: "Subject", type: DataTableColumnTypes.Sort },
    { value: "Recipients", type: DataTableColumnTypes.Text },
    { value: "Failed To Send", type: DataTableColumnTypes.Text },
];

export const hierarchyImportColumnNames = [
    { value: "Upload File Name", type: DataTableColumnTypes.Text },
    { value: "Created By", type: DataTableColumnTypes.Text },
    { value: "Date Created", type: DataTableColumnTypes.Text },
    { value: "Date Completed", type: DataTableColumnTypes.Text },
    { value: "Status", type: DataTableColumnTypes.Text },
    { value: "Error", type: DataTableColumnTypes.Text },
];

export const reviewReportSetHistoryColumnNames = [
    { value: "Report Name", type: DataTableColumnTypes.Text },
    { value: "Rating", type: DataTableColumnTypes.Text },
    { value: "Status", type: DataTableColumnTypes.Text },
    { value: "Date Published", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const reviewReportSetFileColumnNames = [
    { value: "Version", type: DataTableColumnTypes.Text },
    { value: "File Name", type: DataTableColumnTypes.Text },
    { value: "Status", type: DataTableColumnTypes.Text },
    { value: "Date Published", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const pendingAdhocsColumnNames = [
    { value: "Assigned Node Path", type: DataTableColumnTypes.Text },
    { value: "Country", type: DataTableColumnTypes.Text },
    { value: "Checklist Name", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Triggered Date", type: DataTableColumnTypes.Text },
    { value: "Triggered For", type: DataTableColumnTypes.Text },
    { value: "Triggered By", type: DataTableColumnTypes.Text },
    { value: "Referential Links", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const questionSetActionsColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "Calendar Entry", type: DataTableColumnTypes.Text },
    { value: "Review", type: DataTableColumnTypes.Text },
    { value: "Start Date", type: DataTableColumnTypes.Text },
    { value: "End Date", type: DataTableColumnTypes.Text },
];

export const checklistQuestionSetsColumnNames = [
    { value: "Name", type: DataTableColumnTypes.Text },
    { value: "ActiveDateNoTimeZone", type: DataTableColumnTypes.Text },
    { value: "Expiry Date", type: DataTableColumnTypes.Text },
    { value: "Schedule Description", type: DataTableColumnTypes.Text },
    { value: "Viewable from", type: DataTableColumnTypes.Text },
    { value: "Base Start Time of Day", type: DataTableColumnTypes.Text },
    { value: "Send reminder when", type: DataTableColumnTypes.Text },
    { value: "Schedule", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const checklistQuestionSetsNestedQuestionsColumnNames = [
    { value: "Question Text", type: DataTableColumnTypes.Text },
    { value: "Order Index", type: DataTableColumnTypes.Text },
    { value: "Open Before Due", type: DataTableColumnTypes.Text },
    { value: "Due by Time", type: DataTableColumnTypes.Text },
    { value: "Escalation L1 Time", type: DataTableColumnTypes.Text },
    { value: "Escalation L2 Time", type: DataTableColumnTypes.Text },
    { value: "Escalation L3 Time", type: DataTableColumnTypes.Text },
];

export const applicableHierarchyLevelValuesColumnNames = [
    { value: "Hierarchy Level Value", type: DataTableColumnTypes.Link },
    { value: "Hierarchy Level", type: DataTableColumnTypes.Link },
];

export const hierarchyLevelValueActionsColumnNames = [
    { value: "Hierarchy Level Value", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const checklistReportSummaryColumnNames = [
    { value: "Path", type: DataTableColumnTypes.Text },
    { value: "Completed Date", type: DataTableColumnTypes.Text },
    { value: "Checklist", type: DataTableColumnTypes.Text },
    { value: "Question Set", type: DataTableColumnTypes.Text },
    { value: "Actions", type: DataTableColumnTypes.Text },
];

export const checklistReportDetailsColumnNames = [
    { value: "Question", type: DataTableColumnTypes.Text },
    { value: "Answer", type: DataTableColumnTypes.Text },
    { value: "Desirability", type: DataTableColumnTypes.Text },
    { value: "Comment", type: DataTableColumnTypes.Text },
    { value: "Due Date", type: DataTableColumnTypes.Text },
    { value: "Completed Date", type: DataTableColumnTypes.Text },
    { value: "Completed By", type: DataTableColumnTypes.Text },
    { value: "Attached Evidence", type: DataTableColumnTypes.Text },
    { value: "Nested Questions", type: DataTableColumnTypes.Text },
];
