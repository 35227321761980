import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { Response } from "../../responses/common/response-response";
import { createUserGroup } from "../../../data/services/users/user-groups-service";
import { CreateUserGroupRequest } from "../../requests/users/create-user-group-request";
import { CreateUserGroupDto } from "../../dtos/user-groups/user-group-dto";

export const useCreateUserGroup = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateUserGroupDto
> => {
    const url = useUrl();

    return useMutation((dto: CreateUserGroupDto) => {
        return createUserGroup(url.baseUrl, new CreateUserGroupRequest(dto));
    });
};