import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { areQueriesLoading, isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { useGetUserGroupDetails } from "../../../domain/viewmodels/user-groups/view-user-group-details-viewmodel";
import { useSoftDeleteUserGroup } from "../../../domain/viewmodels/users/delete-user-group-viewmodel";

const DeleteUserGroupContainer: React.FC = () => {
    const userGroupId = Number(useParams().userGroupId);

    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const userGroupDetails = useGetUserGroupDetails(userGroupId);

    const softDeleteUserGroup = useSoftDeleteUserGroup();

    useLoader(areQueriesLoading([userGroupDetails]) || isMutationLoading(softDeleteUserGroup), DeleteUserGroupContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.UserGroups);
    }, []);

    const handleDeleteUserGroup = (): void => {
        softDeleteUserGroup.mutate(userGroupId, {
            onSuccess: async () => navigate(getPath(AccordionTitles.UserGroups)),
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={handleDeleteUserGroup} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("UserGroupName"), userGroupDetails?.data?.name),
        new TextConfirmationRow(t("EmailAddress"), userGroupDetails?.data?.email)
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("UserGroupDeleteTitle")}
            panelTitle={t("DeleteUserGroupConfirmation")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default DeleteUserGroupContainer;
