import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { Common, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import queryClient from "../../../data/query-client";
import { useCreateUserGroup } from "../../../domain/viewmodels/users/create-user-group-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { getPath } from "../../../core/utilities/getPath";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { CreateUserGroupDto } from "../../../domain/dtos/user-groups/user-group-dto";

const CreateUserGroupContainer: React.FC = () => {
    
    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const createUserGroup = useCreateUserGroup();

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    const handleSubmit = (form: CreateUserGroupDto): void => {

        createUserGroup.mutate(
            form,
            {
                onSuccess: async () => {
                    toast.addToast(createSuccessToastProps([t('UserGroupCreated')]));
                    navigate(`${getPath(AccordionTitles.UserGroups)}`);
                    queryClient.invalidateQueries(["getUserGroupDetails"]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const createUserGroupSchema = (): object => {
        return object({
            name: string()
                .max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common }))
                .required(),
            description: string().max(1500, t("MustBe1500CharactersOrLess", { keyPrefix: Common })),
            emailAddress: string()
                .max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common }))
                .required(),
        });
    };

    return (
        <>
            <PageHeading>{t("UserGroupCreateTitle")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("UserGroupCreateTitle")}>
                <Formik<CreateUserGroupDto>
                    initialValues={{
                        name: "",
                        description: "",
                        emailAddress: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={createUserGroupSchema}
                >
                    {({ handleChange }) => (
                        <Form>
                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name")}
                                type="text"
                                onFormikChange={handleChange}
                            />

                            <SbFormikTextFieldGroup
                                name="description"
                                label={t("Description")}
                                type="text"
                                onFormikChange={handleChange}
                            />

                            <SbFormikTextFieldGroup
                                name="emailAddress"
                                label={t("Email")}
                                type="text"
                                onFormikChange={handleChange}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(`${getPath(AccordionTitles.UserGroups)}`)} />
                            </EndAlignedDiv>
                        </Form>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateUserGroupContainer;