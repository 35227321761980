import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Common, Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { UserGroupDto } from "../../../domain/dtos/users/user-group-dto";
import { useGetUserGroupDetails } from "../../../domain/viewmodels/user-groups/view-user-group-details-viewmodel";
import { useUpdateUserGroupDetails } from "../../../domain/viewmodels/users/edit-user-group-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { object, string } from "yup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { Form, Formik } from "formik";
import { getPath } from "../../../core/utilities/getPath";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { EditUserGroupDto } from "../../../domain/dtos/user-groups/user-group-dto";

const EditUserGroupContainer: React.FC = () => {
    const userGroupId = Number(useParams().userGroupId);
    const [editUserGroupDto, setEditUserGroupDto] = useState<UserGroupDto | null>(null);

    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const userGroupDetails = useGetUserGroupDetails(userGroupId);
    const updateUserGroupDetails = useUpdateUserGroupDetails();

    useLoader(
        areQueriesLoading([userGroupDetails]) || isMutationLoading(updateUserGroupDetails),
        EditUserGroupContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, AccordionTitles.Users);
    }, []);

    useEffect(() => {
        if (areQueriesSuccessful([userGroupDetails])) {
            setEditUserGroupDto(userGroupDetails!.data!);
        }
    }, [userGroupDetails.fetchStatus]);

    const handleSubmit = (request: EditUserGroupDto): void => {

        updateUserGroupDetails.mutate(
            request,
            {
                onSuccess: async () => {
                    toast.addToast(createSuccessToastProps([t('UserGroupUpdated')]));
                    navigate(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}`);
                    queryClient.invalidateQueries(["getUserGroupDetails"]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const editUserGroupSchema = (): object => {
        return object({
            name: string()
                .max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common }))
                .required(),
            description: string().max(1500, t("MustBe1500CharactersOrLess", { keyPrefix: Common })),
            emailAddress: string()
                .max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common }))
                .required(),
        });
    };

    return (
        <>
            <PageHeading>{t("UserGroupEditTitle")}</PageHeading>
            <SectionVerticalSpace />

            {editUserGroupDto && (
                <TextTitledPanel title={t("UserGroupEditTitle")}>
                    <Formik<EditUserGroupDto>
                        initialValues={{
                            userGroupId: editUserGroupDto.userGroupId,
                            name: editUserGroupDto.name,
                            description: editUserGroupDto.description,
                            emailAddress: editUserGroupDto.email
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={editUserGroupSchema}
                    >
                        {({ handleChange, errors }) => (
                            <Form>
                                <SbFormikTextFieldGroup
                                    name="name"
                                    label={t("name")}
                                    type="text"
                                    defaultValue={editUserGroupDto!.name}
                                    onFormikChange={handleChange}
                                />

                                <SbFormikTextFieldGroup
                                    name="description"
                                    label={t("Description")}
                                    type="text"
                                    defaultValue={editUserGroupDto!.description}
                                    onFormikChange={handleChange}
                                />

                                <SbFormikTextFieldGroup
                                    name="email"
                                    label={t("Email")}
                                    type="text"
                                    defaultValue={editUserGroupDto!.email}
                                    onFormikChange={handleChange}
                                />

                                <EndAlignedDiv>
                                    <SaveButton type="submit" />
                                    <CancelButton onClick={() => navigate(`${getPath(AccordionTitles.UserGroups)}/${userGroupId}`)} />
                                </EndAlignedDiv>
                            </Form>
                        )}
                    </Formik>6
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditUserGroupContainer;
